<template>
  <div class="wrapper" v-if="gameStarted">
    <!-- <div id="odeck" class="deck enemyDeck turnIndicator tipos" :class="{ turnC: turn }"> -->
    <div class="cardArea">
      <div v-for="(n, j) in enemyCards" :key="j">
        <div v-if="j != playerNr" style="text-align: center;">
          {{ names[j] }}
        </div>
        <div v-if="j != playerNr" class="deck enemyDeck" :id="'p' + j">
          <div v-for="(n, i) in enemyCards[j]" :key="i" class="card-outer">
            <div class="card-back"></div>
            <div class="card-front bf" :class="centerCard[0]">
              <div class="tl"> {{ renderCard(centerCard) }} </div>
              <div class="oval"></div>
              <div class="br"> {{ renderCard(centerCard) }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="board" @drop="boardDrop($event)" @dragenter.prevent @dragover.prevent>
      <!-- <div @click="testf()">button</div> -->
      <div>
        <div id="cardClone" class="card-outer"></div>
        <div id="pc" class="card-outer pc">
          <div class="card-back bf"></div>
          <div class="card-front" :class="centerCard[0]" @click="clickBoard()">
            <div class="tl"> {{ renderCard(centerCard) }} </div>
            <div class="oval" :class="activeColor" v-if="centerCard[0] == 'd'"></div>
            <div class="oval" v-if="centerCard[0] != 'd'"></div>
            <div class="br"> {{ renderCard(centerCard) }} </div>
          </div>
        </div>
      </div>
      <div id="boardDrawCard" class="card-outer" @click="drawCard()">
        <div class="card-back"></div>
        <div class="card-front bf" :class="centerCard[0]">
          <div class="tl"> {{ renderCard(centerCard) }} </div>
          <div class="oval"></div>
          <div class="br"> {{ renderCard(centerCard) }} </div>
        </div>
      </div>
    </div>
    <div id="pdeck" class="cardArea deck player turnIndicator" :class="{ turnC: turn != playerNr }">
      <div v-for="(n,i) in cards" :key="i" class="card-outer" draggable="true" @dragstart="startDrag($event, i)"
        @drop="deckDrop($event, i)" @dragenter.prevent @dragover.prevent @click="clickHandCard(i)">
        <div class="card-back bf"></div>
        <div class="card-front" :class="n[0]">
          <div class="tl"> {{ renderCard(n) }}</div>
          <div class="oval">
            <div class="r" v-if="n[0] == 'd'"></div>
            <div class="b" v-if="n[0] == 'd'"></div>
            <div class="g" v-if="n[0] == 'd'"></div>
            <div class="y" v-if="n[0] == 'd'"></div>
          </div>
          <div class="br"> {{ renderCard(n) }} </div>
        </div>
        <!-- {{ n }} -->
      </div>
    </div>
    <div v-if="showColorPicker" class="colorwheel">
      <div class="oval">
        <div class="r" @click="playColorPickCard('r')"></div>
        <div class="b" @click="playColorPickCard('b')"></div>
        <div class="g" @click="playColorPickCard('g')"></div>
        <div class="y" @click="playColorPickCard('y')"></div>
      </div>
    </div>
  </div>
  <div v-else class="landingPageWrapper">
    <h1>Intervall X</h1>
    Name: <input v-model="pName" placeholder="Name" />
    <div class="flexFF">
      <div v-if="!inRoom">
        <input v-model="inputRoomId" placeholder="room id" />
        <button @click="joinRoom()">join room</button>
      </div>
      <div>
        <button v-if="!inRoom" @click="createRoom()">create room</button>
        <div v-if="inRoom" class="roomCreated">
          <div>
            room ID:<br>
            <h3>{{ roomid }}</h3>
          </div>
          <div>
            players:
            <ul>
              <li v-for="(n, i) in names" :key="i" :class="{ highlightText: i == this.playerNr }"> {{ n }}</li>
            </ul>
          </div>
          <button @click="startGame()">start game</button>
          <button>leave room</button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
let rndNames = ["Quokka", "Cat", "Fennec Fox", "Sea Otter", "Chevrotain", "Hedgehog", "Axolotl", "Meerkat", "Red Panda", "Pygmy Marmoset"];
import { nextTick } from 'vue'
export default {
  name: 'App',
  data: function () {
    return {
      // cards: ["r1", "dd4", "gr", "dc1", "r+2", "b6", "b6", "b8", "b9", "b10", "b11", "y12"],
      // gameStarted: true,
      // enemyCards: [8, 8, 8, 8,8,8],
      // names: ['riven', 'draven', 'jax', 'ahri','akali','fiora'],
      cards: [],
      gameStarted: false,
      enemyCards: [],
      names: [],
      centerCard: "dd4",
      activeColor: "r",
      playerNr: 0,
      pName: rndNames[Math.floor(Math.random() * (rndNames.length - 1 - 0 + 1)) + 0],
      roomid: null,
      inputRoomId: null,
      connection: null,
      turn: null,
      inRoom: false,
      showColorPicker: false,
      cardBuffer: null
    }
  },
  methods: {
    testf(){ //can be removed -- just for testing
      this.names.push("yorick");
      this.enemyCards.push(8);
    },
    renderCard(id) {
      let d = id.substring(1, 2);
      let r = "";
      switch (d) {
        case "r":
          r = "↺";
          break;
        case "d":
          r = "+4";
          break;
        case "c":
          r = "🎨";
          break;
        case "s":
          r = "∅";
          break;
        default:
          r = id.substring(1);
          break;
      }
      return r;
    },
    animateDrawCard(player, amount) {
      var pnr = this.playerNr;
      nextTick().then(function () {
        var bdc = document.getElementById("boardDrawCard");
        var bdcbr = bdc.getBoundingClientRect();
        var ac = player == pnr ? document.getElementById("pdeck") : document.getElementById("p" + player);
        var rotate = player == pnr ? "rotateY(180deg)" : "";

        for (let i = 0; i < amount; i++) {
          var lc = ac.children[ac.childElementCount - (i + 1)];
          var lcbr = lc.getBoundingClientRect();
          lc.animate(
            [
              // keyframes
              { transform: "translate(" + (bdcbr.x - lcbr.x) + "px," + (bdcbr.y - lcbr.y) + "px) " + rotate + "" },
              { transform: "translate(0,0)" },
            ],
            {
              // timing options
              duration: 400,
              iterations: 1,
            },
          );
        }
      })
    },
    animatePlayCard(msg) {
      var e = document.getElementById("pc");
      var t = document.getElementById("cardClone");
      var c1 = document.getElementById("p" + msg.pNr).children[0];
      t.innerHTML = e.innerHTML;
      this.centerCard = msg.card;
      this.activeColor = msg.color;
      var y = c1.getBoundingClientRect().y - e.getBoundingClientRect().y;
      var x = c1.getBoundingClientRect().x - e.getBoundingClientRect().x;
      e.animate(
        [
          {transform: "translate("+x+"px,"+y+"px) rotateY(180deg)"},
          {transform: "translate("+x/2+"px,"+y/2+"px) rotateY(180deg)",offset: 0.5},
          {transform: "translate(0,0) rotateY(0deg)"}
        ],
        {
          duration:400,
          iterations:1,
        }
      );
    },
    startDrag(evt, index) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('cardI', index);
    },
    boardDrop(evt) {
      const cardI = evt.dataTransfer.getData('cardI');
      if (this.cards[cardI][0] == 'd') {
        this.cardBuffer = cardI;
        this.showColorPicker = true;
      }
      else {
        let payload = {
          "id": "playCard",
          "card": this.cards[cardI] 
        }
        this.connection.send(JSON.stringify(payload));
      }
    },
    clickHandCard(cardI) {
      if (this.cards[cardI][0] == 'd') {
        this.cardBuffer = cardI;
        this.showColorPicker = true;
      }
      else {
        let payload = {
          "id": "playCard",
          "card": this.cards[cardI] 
        }
        this.connection.send(JSON.stringify(payload));
      }
    },
    playColorPickCard(c) {
      this.showColorPicker = false;
      let payload = {
        "id": "playCard",
        "card": this.cards[this.cardBuffer],
        "color": c
      }
      this.connection.send(JSON.stringify(payload));
    }
    ,
    deckDrop(evt, indT) {
      let indS = parseInt(evt.dataTransfer.getData('cardI'));
      let buffer = this.cards[indS];
      if (indS > indT) {
        indT += 1;
        for (var i = indS; i > indT; i--) {
          this.cards[i] = this.cards[i - 1];
        }
      }
      else if (indT > indS) {
        for (i = indS; i < indT; i++) {
          this.cards[i] = this.cards[i + 1];
        }
      }
      this.cards[indT] = buffer;
    },
    drawCard() {
      let payload = { "id": "drawCard" };
      this.connection.send(JSON.stringify(payload));
    },
    clickBoard() {
      let payload = { "id": "clickBoard" };
      this.connection.send(JSON.stringify(payload));
    },

    createRoom() {
      let payload = {
        "id": "createRoom",
        "name": this.pName
      };
      this.connection.send(JSON.stringify(payload));
    },
    startGame() {
      let payload = {
        "id": "startGame"
      };
      this.connection.send(JSON.stringify(payload));
    },
    joinRoom() {
      let payload = {
        "id": "joinRoom",
        "gid": this.inputRoomId,
        "name": this.pName
      };
      this.connection.send(JSON.stringify(payload));
    }
  },
  watch:{
    names:{ //make cards smaller the more players there are
      handler: function() {
        // because we are using this handler immideatly we need to wait for data changes using nextTick.
        this.$nextTick(() => {
          document.documentElement.style.setProperty("--enemyCardW", Math.max(100-5.2*this.names.length,5)+"px");
        })
      },
      immediate: true,
      deep:true
    }
  },
  components: {
    // simpleDrag,
    // HelloWorld
  },
  mounted: function () {
    // this.connection = new WebSocket('ws://localhost:3000/');
    // this.connection = new WebSocket('ws://192.168.178.108:3000/');
    this.connection = new WebSocket('wss://sdohm.xyz:3000/');
    this.connection.onmessage = (event) => {
      let msg = JSON.parse(event.data)
      switch (msg.id) {
        case "roomCreated":
          this.roomid = msg.gid;
          this.inRoom = true;
          break;
        case "playerNr":
          this.playerNr = msg.nr;
          // this.inRoom = true;
          break;
        case "bcPlayers":
          this.names = msg.names;
          break;
        case "error":
          console.log(msg.error);
          // alert(msg.error)
          break;
        case "gameStarted":
          this.gameStarted = true;
          this.cards = msg.cards;
          this.enemyCards = msg.enemyCards;
          this.centerCard = msg.centerCard;
          this.activeColor = msg.color;
          break;
        case "cardDrawn":
          msg.card.forEach(e => {
            this.cards.push(e);
          });
          this.animateDrawCard(this.playerNr, msg.card.length);
          break;
        case "enemyCardUpdate":
          var odc = this.enemyCards[msg.pNr] < msg.card;
          var l = msg.card - this.enemyCards[msg.pNr];
          this.enemyCards[msg.pNr] = msg.card;
          if (odc) {
            this.animateDrawCard(msg.pNr, l);
          }
          break;
        case "updateCenterCard":
          if (this.turn != this.playerNr)
            this.animatePlayCard(msg);
          else {
            this.centerCard = msg.card;
            this.activeColor = msg.color;
          }
          break;
        case "removeCard":
          var cIndex = this.cards.indexOf(msg.card);
          this.cards.splice(cIndex,1);
          break;
        case "turn":
          this.turn = msg.value;
          break;
        default:
          console.log(msg);
          break;
      }
    }
  }
}
</script>

<style src="@/style.css"></style>